<template>
  <form
    @submit.prevent="triggerSearch"
  >
    <div class="artifact-search">
      <input
        class="textfield"
        type="search"
        :placeholder="props.placeholder"
        v-model="searchStore.searchInputs"
        @focus="openSignUpDialog()"
      >
      <div
        class="icon"
      >
        <svg
          class="svg"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: 'スタイリッシュ」「ミニマル」などキーワードを入れて検索できます',
  },
  pushRoutes: {
    type: String,
    default: '',
  },
})
const searchStore = useSearchStore()
const generateUrl = computed(() => searchStore.generateUrl)
const route = useRoute()
const router = useRouter()
const isDemoRoute = computed(() => route.path === '/cgbank/demo')
const dialogStore = useDialogStore()

const openSignUpDialog = async () => {
  if (isDemoRoute.value) {
    dialogStore.openDialog('sign-up-dialog')
  }
}

const triggerSearch = (): void => {
  const currentPath = route.path
  const newUrl = `${currentPath}${props.pushRoutes}${generateUrl.value}`
  router.push(newUrl)
}
</script>

<style lang="scss">
.artifact-search {
  position: relative;
  overflow: hidden;

  > .icon {
    position: absolute;
    padding-inline-end: 0.75rem;
    align-items: center;
    display: flex;
    inset-inline-end: 0px;
    top: 0px;
    bottom: 0px;
    pointer-events: none;
  }

  > .icon > .svg {
    width: 1rem;
    height: 1rem;
  }

  > .textfield {
    color: rgb(17, 24, 39);
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1rem;
    border: none;
    width: 100%;
    display: block;
    border-radius: 10px;

    &:focus {
      outline: none;
    }
  }

  > .button.-search {
    color: white;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: black;
    inset-inline-end: 0.625rem;
    bottom: 0.635rem;
    position: absolute;
    cursor: pointer;
    text-transform: none;
  }
}
</style>
